import React from 'react';

import { type ITournamentRules } from '../interfaces';

export const BettingEventRules = ({ t }: ITournamentRules): JSX.Element => (
  <div className="tournament-rules-container">
    <div className="back-shadow">
      <section className="tr-section-container">
        <ol>
          <li>{t('eventTerms1')}</li>
          <li>{t('eventTerms2')}</li>
          <li>{t('eventTerms3')}</li>
          <li>{t('eventTerms4')}</li>
          <li>{t('eventTerms5')}</li>
          <li>{t('eventTerms6')}</li>
        </ol>
      </section>
    </div>
  </div>
);
