const languageCodes = {
  chineseTraditional: 'zh-Hant',
  chineseSimplified: 'zh-Hans',
  english: 'en'
};

const websiteLanguageCodes = {
  chineseTraditional: 'tw',
  chineseSimplified: 'cn',
  english: 'en'
};

const languages = [
  {
    name: '简',
    languageCode: languageCodes.chineseSimplified
  },
  {
    name: '繁',
    languageCode: languageCodes.chineseTraditional
  },
  {
    name: 'EN',
    languageCode: languageCodes.english
  }
  // },
  // {
  //   name: 'JP',
  //   languageCode: languageCodes.japanese
  // },
  // {
  //   name: 'VI',
  //   languageCode: languageCodes.vietnamese
  // },
  // {
  //   name: 'TH',
  //   languageCode: languageCodes.thai
  // },
  // {
  //   name: 'ID',
  //   languageCode: languageCodes.indonesian
  // },
  // {
  //   name: 'KR',
  //   languageCode: languageCodes.korean
  // },
  // {
  //   name: 'KH',
  //   languageCode: languageCodes.khmer
  // }
];

export { languageCodes, languages, websiteLanguageCodes };
