import React, { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { languageCodes, websiteLanguageCodes } from './languages/languages';
import {
  Details,
  FaqSection,
  Footer,
  Introduction,
  PreHeader
} from './components';

import './styles/App.scss';
const Banner = lazy(async () => await import('./components/Banner'));

function App (): JSX.Element {
  const mobileBreakPoint = 750;
  const { t, i18n } = useTranslation();
  const isChinese =
    i18n.resolvedLanguage === languageCodes.chineseTraditional ||
    i18n.resolvedLanguage === languageCodes.chineseSimplified;

  const getWindowSize = (): boolean => (window.innerWidth <= mobileBreakPoint);
  const [isMobileView, setIsMobileView] = useState<boolean>(getWindowSize());
  const [showRankings, setShowRankings] = useState<boolean>(false);

  useEffect(() => {
    function handleWindowResize (): void {
      setIsMobileView(window.innerWidth <= mobileBreakPoint);
    }

    window.addEventListener('resize', handleWindowResize);

    const params = new URLSearchParams(window.location.search);
    const lang: string | null = params.get('lang');

    if (lang !== null && Object.values(languageCodes).includes(lang)) {
      void i18n.changeLanguage(lang);
    }

    if (lang === websiteLanguageCodes.chineseTraditional) {
      void i18n.changeLanguage(languageCodes.chineseTraditional);
    }
    if (lang === websiteLanguageCodes.chineseSimplified) {
      void i18n.changeLanguage(languageCodes.chineseSimplified);
    }

    document.title = t('miCardBaccaratTitle');

    return () => { window.removeEventListener('resize', handleWindowResize); };
  }, []);

  return (
    <div className="App">
      <header>
        <PreHeader
          isMobileView={isMobileView}
          setShowRankings={setShowRankings}
          showRankings={showRankings}
          t={t}
          i18n={i18n}
          isChinese={isChinese}
        />
      </header>
      <Banner
        isMobileView={isMobileView}
        showRankings={showRankings}
        t={t}
        i18n={i18n}
        isChinese={isChinese}
      />
      <main className="desktop-background">
        <LazyLoadComponent>
          <Introduction
            isMobileView={isMobileView}
            t={t}
            i18n={i18n}
            isChinese={isChinese}/>
        </LazyLoadComponent>
        <LazyLoadComponent>
          <Details t={t}/>
        </LazyLoadComponent>
        <LazyLoadComponent>
          <FaqSection t={t} isChinese={isChinese} i18n={i18n} />
        </LazyLoadComponent>
        <LazyLoadComponent>
          <Footer isMobileView={isMobileView}/>
        </LazyLoadComponent>
      </main>
    </div>
  );
}

export default App;
