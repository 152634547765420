import React, { useState } from 'react';
import bookmarkStar from '../assets/newTournament/bookmark_star.webp';

import { type IDetails } from '../interfaces';
import tabUnderline from '../assets/newTournament/tab_underline.webp';

import { TournamentRules } from './TournamentRules';

import '../styles/DetailsSection.scss';
import { BettingEventRules } from './BettingEventRules';

export const Details = ({ t }: IDetails): JSX.Element => {
  const TABS = {
    tournamentRules: 'TOURNAMENT_RULES',
    bettingEventRules: 'BETTING_EVENT_RULES'
  };
  const [tab, setTab] = useState<string>(TABS.tournamentRules);
  return (
    <section className="details-container" id="eventDetails">
      <section className="title-container">
        <aside>
          <img src={bookmarkStar} width="24px" height="30px" alt="bookmark star"/>
        </aside>
        <h2>{t('eventDetails')}</h2>
      </section>
      <div className="details-content">
        <div className="details-section-nav-container">
          <button className={tab === TABS.tournamentRules ? 'disabled-btn' : 'btn-highlight'}
            onClick={() => { setTab(TABS.tournamentRules); }}>
            <strong>{t('eventRules')}</strong>
          </button>
          <button className={tab === TABS.bettingEventRules ? 'disabled-btn' : 'btn-highlight'}
            onClick={() => { setTab(TABS.bettingEventRules); }}
          >
            <strong>{t('eventTerms')}</strong>
          </button>
        </div>
        <div style={{ marginTop: '-12px' }}>
          <img src={tabUnderline} width="100%" height="4px" alt="tab underline" />
        </div>
        {tab === TABS.tournamentRules && <TournamentRules t={t}/>}
        {tab === TABS.bettingEventRules && <BettingEventRules t={t} />}
      </div>
    </section>
  );
};
