import React from 'react';

import AGLogo from '../assets/introduction/web_mt_misc_logo_ag.svg';

import leaderboardIcon from '../assets/newTournament/social_leaderboard.webp';

import step2Sc from '../assets/micardTournament/step_2_micard.webp';
import step2En from '../assets/micardTournament/step_2_en.webp';
import step2Tc from '../assets/micardTournament/step_2_tc.webp';
import step3En from '../assets/micardTournament/step_3_en.webp';
import step3Tc from '../assets/micardTournament/step_3_tc.webp';
import step3Sc from '../assets/micardTournament/step_3_sc.webp';
import step4BtnEn from '../assets/micardTournament/Button_en.webp';
import step4BtnCn from '../assets/micardTournament/Button_tc.webp';

import { type IIntroduction } from '../interfaces';

import '../styles/Introduction.scss';
import { languageCodes } from '../languages/languages';

export const Introduction = ({ t, i18n, isChinese }: IIntroduction):
JSX.Element => {
  const isTradChinese =
    i18n?.resolvedLanguage === languageCodes.chineseTraditional;

  const checkStep2 = (): string => {
    if (isChinese) {
      if (isTradChinese) return step2Tc;
      return step2Sc;
    }

    return step2En;
  };
  const checkStep3 = (): string => {
    if (isChinese) {
      if (isTradChinese) return step3Tc;
      return step3Sc;
    }

    return step3En;
  };
  return (
    <section className="introduction-container" id="eventOverview">
      <div className="intro-title">
        <img src={leaderboardIcon} height="30px" width="30px" alt="leaderboard icon"/>
        <h2 style={{ marginTop: '26px' }}>{t('eventOverview')}</h2>
      </div>
      <p>{t('eventOverviewDetails')}</p>
      <div className="how-to-enter-container">
        <section className="how-to-take-part" id="howToParticipate">
          <h2 style={{ marginTop: '0px' }}>{t('howToParticipate')}</h2>
          <section className="steps-container">
            <div>
              <aside>
                <p>{t('step') + ' '}</p>
                <strong className="card-header">1</strong>
              </aside>
              <div className="img-container">
                <img src={AGLogo} alt="AG Logo"/>
              </div>
              <p className="step-instructions" style={{ marginTop: '40px' }}>{t('howTo1')}</p>
            </div>
            <div>
              <aside>
                <p>{t('step') + ' '}</p><strong className="card-header">2</strong>
              </aside>
              <div className="img-container">
                <img src={checkStep2()} alt="step 2 logo"/>
              </div>
              <p className="step-instructions" style={{ marginTop: '40px' }}>{t('howTo2')}</p>
            </div>
            <div>
              <aside>
                <p>{t('step') + ' '}</p><strong className="card-header">3</strong>
              </aside>
              <div className="img-container">
                <img src={checkStep3()} alt="step 3 logo"/>
              </div>
              <p className="step-instructions" style={{ marginTop: '40px' }}>{t('howTo3')}</p>
            </div>
            <div>
              <aside>
                <p>{t('step') + ' '}</p>
                <strong className="card-header">4</strong>
              </aside>
              <div className="img-container">
                <img className="step-4-btn" src={isChinese ? step4BtnCn : step4BtnEn} alt="step 4 button"/>
              </div>
              <p className="step-instructions" style={{ marginTop: '40px' }}>{t('howTo4')}</p>
            </div>
          </section>
        </section>
      </div>
    </section>);
};
