import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { languageCodes } from './languages';
import {
  cnSimplified,
  cnTraditional,
  enTranslation
} from './translations';

void i18n
  .use(initReactI18next)
  .init({
    fallbackLng: languageCodes.chineseSimplified,
    debug: true,
    interpolation: {
      escapeValue: false
    },
    resources: {
      [languageCodes.english]: {
        translation: { ...enTranslation }
      },
      [languageCodes.chineseSimplified]: {
        translation: { ...cnSimplified }
      },
      [languageCodes.chineseTraditional]: {
        translation: { ...cnTraditional }
      }
    }
  });

export default i18n;
