export const enTranslation: Record<string, string> = {
  gog: 'Master Tournament',
  stayTuned: 'STAY TUNED',
  introduction: 'INTRODUCTION',
  award: 'Award',
  details: 'DETAILS',
  faq: 'F.A.Q',
  new: 'New',
  topMaster: 'TOP MASTER',
  backToTop: 'Back to Top',
  introductionDetails: 'AG Master Tournament is based on the most popular ' +
   'Baccarat game which caters to the current online most popular “Sit n Go” method' +
   ' to create. We use all standard fair and clear tournament rules' +
   ' to bring the most exciting online baccarat tournament for all' +
   ' types of players.',
  howToEnter: 'How to Enter',
  gameProcess: 'Game Process',
  step: 'Step',
  step1: 'The user account must be a real money account of the AG platform',
  step2: 'Enter the AG platform and select the corresponding game venue in the \'Master Tournament\' hall to register',
  howToJoin1: 'This tournament is for AG real money account only.',
  howToJoin2: 'Select Master Tournament lobby ' +
    'and select the desired tournament.',
  satelliteTournament: 'Satellite tournament do not have a scheduled' +
  ' start time. A sit and go begins as soon as there are enough people' +
  ' registered at a table. Winner will get a ticket to register' +
  ' in Master tournament.',
  masterTournament: 'Master tournament is using Fixed Time mode to begin. ' +
    'Once the participant reached the target, ' +
    'tournament will start immediately.',
  seasonTournament: 'Season Tournament is using Fixed Time mode to begin. ' +
    'Invite the Master Tournament winners in the season to compete with the ' +
    'players who have signed up for the points. ' +
    'The champion will receive a super high prize!',
  awardDetails: 'All winners award will have direct deposit ' +
    'into user account without waiting and review.',
  qualifyingRound: 'Satellite Tournament',
  qualRoundText: 'The winners of the Satellite Tournaments will get tickets to enter the Master Tournament and win more prizes.',
  gogTickets: 'Master Tournament Tickets /\nUser Points',
  gogMasterTournament: 'Master Tournament',
  gogStep3: 'Master Tournament is using Fixed Time mode to begin. Once the participant has reached the target, the tournament will start immediately.',
  tournamentRules: 'Tournament Rules',
  fairness: 'Fairness and Transparency',
  regAndWithdrawal: 'Register and Withdrawal',
  inGame: 'In Game',
  ranking: 'Ranking',
  withdraw3: 'Withdrawing after registration (when the required people is not met) and the tournament has not started',
  withdraw3Child: 'Players could withdraw immediately, and within 3 minutes players cannot register the same type of tournament again, the system will return the deducted points/tickets.',
  withdraw4: 'Withdrawing after registration (reached the number of entrants) and the tournament has not started:',
  withdraw4Child: 'Participants must enter the tournament to withdraw, the participant will be counted as eliminated by the system. All deducted user points or tickets will not be refunded.',
  withdraw5: 'Participants deciding to retire during the tournament',
  withdraw5Child: 'During the tournament if any one of the participants select another game or leave the tournament, the rankngs and winning opportunities will be judged by the number of valid bets and the number of rounds the participants have placed.',
  withdraw6: 'Player will not be able to re-register the same game after they quit that game.',
  withdraw7: 'Each player can only withdraw from the registered tournament within the same day up to 5 times, if more than 5 times the player is not allowed to register the same type of tournament.',
  tournamentRules1: 'After the player registered the tournament, ' +
    'the tournament will be bet with a fixed tournament chips in the ' +
    'specific tournament table. The tournament chips have not connection ' +
    'with the player’s personal account balance and User Points. ' +
    'All tournament chips will not be accumulated to the next tournament ' +
    'session. If the player’s account points are not enough, they may place bet in normal table to increase valid bet amount and exchange the account points',
  tournamentRules2: 'To ensure fairness and transparency of the system, ' +
    'after the player register successfully, the assigned seat are all ' +
    'randomly arranged by the system. ' +
    'Contestants may not select or exchange seat with other participants.',
  tournamentRules3: 'All winner’s cash prize will be distributed ' +
    'according to the award structure and all cash will be deposited directly ' +
    'to user account.',
  tournamentRules4: 'Tournament shall be based on rules of non-commission ' +
    'Baccarat. For more details about Baccarat game rules click menu > ' +
    'game rule. Each contestant has 25 seconds betting time limit per hand in' +
    ' each round. Each contestant is required to make at least one ' +
    'minimum bet either on BANKER OR PLAYER for every hand. ' +
    'Contestants are not permitted to bet on TIE/PAIR in lieu of ' +
    'BANKER OR PLAYER.',
  tournamentRules5Part1: 'Prior to the commencement of the Qualifying Round, ' +
    '100,000 worth of tournament chips (tournament chips have no true ' +
    'monetary value and are used only for tournament purpose) will be ' +
    'distributed to each contestant at the designated table, during the ' +
    'tournament contestants betting limit is 1000-10000.',
  tournamentRules5Part2: 'Contestants shall not bet more than their ' +
    'available tournament chips and betting limit per hand is as follows:',
  betItem: 'Bet Item',
  minBet: 'Min Bet',
  maxBet: 'Max Bet',
  banker: 'Banker',
  player: 'Player',
  tie: 'Tie',
  pair: 'Pair',
  tournamentRules6: 'All tournaments before start first 2 hands will be ' +
    'skipped. During the tournament, the shoe will not be shuffled again.',
  tournamentRules7: 'To ensure fairness and transparency among participants, ' +
    'the tournament will use the following promotion and elimination rules:',
  tournamentRules7_1: 'During the game, the system adopts the rules set for ' +
    'each match to promote the designated number of players according to the ' +
    'virtual table for each round, and each round of players ' +
    'who have not been promoted will be eliminated automatically.',
  tournamentRules7_2: 'For example: 56 participating in the tournament, ' +
    'the contestants are located in 8 virtual tables (A、B、C、D、E、F、G 、H ) ' +
    'the game is divided into 4 section (each section 5 rounds), ' +
    'then each section the top three contestant promoted ' +
    '(i.e. A to H table, top 3 players promoted to next round until ' +
    'the tournament finish.',
  tournamentRules7_3: 'All promoted participant will keep the advantage ' +
    'on the tournament chips: if the tournament is set to two participant ' +
    'promoted in each table. When the participants was promoted in the first ' +
    'place in next round, then each table first place participant will be a ' +
    'fixed percentage increase in the initial tournament chips. ' +
    '(i.e. the top player will have different and more tournament chips ' +
    'than the other participants in next round)',
  tournamentRules7_4: 'The system uses as close as possible to ensure ' +
    'that the average number of players per table, each round promoted ' +
    'participants will once again randomly assigned to the different ' +
    'tables again during the tournament.',
  tournamentRules8: 'Prior to the commencement of the tournament start, ' +
    'the following limited special bet function will be distributed to ' +
    'each contestant :',
  tournamentRules8_1: 'Blind bet is one of the bet strategy, ' +
    'mainly hidden participant own placed bet amount. ' +
    'The content of the blind bet shall be disclosed before completion' +
    ' of the hand. The number of blind bets chance are according to the' +
    ' number of the cycles provide in tournament. ' +
    'For example: Total cycles: 5, total number of blinds bets: 5',
  tournamentRules8_2: '“Pass bet” is skip current round betting. ' +
    'If you don’t want to bet the current round, you can just click on ' +
    '“Pass bet” button. The number of pass bets chance are according to the' +
    ' number of the cycles provide in tournament. ' +
    'For example: Total cycles: 5, total number of pass bets: 5',
  tournamentRules9: 'At the end of the tournament session, ' +
    'all used or unused tournament chips and special bets function will be' +
    ' cleared automatically at the end of each match. ' +
    'Participants are not permitted to transfer or loan tournament chips ' +
    'from the other participants.',
  tournamentRules10: 'The ranking rules will use the following ' +
    'order of priority:',
  tournamentRules10_1: 'Promoted participants ranked higher than other ' +
    'eliminated participants, then based on the final remain ' +
    'tournament chips to rank.',
  tournamentRules10_2: 'If the participants with the same remaining ' +
    'tournament chips, then the highest wining rate is ranked higher.',
  tournamentRules10_3: 'If there more than one participants with the same ' +
    'rankings in the above two ranking rules, ' +
    'the participants who register earlier will be ranked higher.',
  tournamentRules11: 'For any reason if a participant is required to leave ' +
    'the table during the session and round, the time of absence must not ' +
    'exceed five (5) hands; otherwise the participants will be disqualified ' +
    'from the prize reward and only allow enter the ranking only.',
  tc: 'Terms and Conditions',
  tc1: 'All tournaments are only for valid AG real money accounts to participant only.',
  tc2: 'During the tournament, player participate “Satellite Tournament” need to use player account’s point to register for the event.',
  tc3: 'All tournament are started on time according to the time announced in the different tournament lobby and we are reserve the right to change, delay or cancel the tournament without prior notice to the player.',
  tc4: 'User Points can be earnt from placed valid bet in AG platform only. For more details about User Points system, please check it on the AG platform.',
  tc5: '“Satellite Tournament” winner will get a “Master Tournament” ticket (if all ticket was all gone, all tournament will not accept registration, first come first serve). All tickets valid during the current monthly event.',
  tc6: 'To ensure the tournament event is for our valuable member, each member is limited to the same account and the same IP address to participate. Those who violate the rules will be disqualified from accepting the award and the serious fraudsters, AG have the rights to deal with titles or deduct related profits without prior notice.',
  tc7: 'The method of dealing with violations is equivalent to terms and conditions policy point 6.',
  tc8: 'If participants violate the competition rules, the participants will lose their eligibility to participate.',
  tc9: 'For all participants who join AG activities, mean agree to the terms and conditions of these activities.',
  tc10: 'AG reserves the rights of the final explanation of this activity.',
  remark: 'Remark',
  registration: 'Registration',
  registration1: 'If a player owns Master Tournament tickets and enough user points at the same time, players can either deduct user points or master tournament tickets.',
  registration2: 'Before any of the tournament start within 20 minutes, each account allow to register one type of tournament only (Satellite or Master tournament)',
  registration2_1: 'To guarantee player able to participate in Master Tournament, before tournament start within 20 minutes, all the registered player with the same account will unable to register for another Satellite Tournament.',
  registration2_2: 'If Master Tournament starts at 20 minutes or more, then player can register for Satellite Tournament. When the Master Tournament start within 20 minutes then previous registered Satellite Tournament, all deducted account points will be refunded.',
  cancelReg: 'Cancel registration',
  cancelReg1: 'Each player allows cancel registration before the tournament start. After cancelled registration within 3 minutes are not allow to register for the same tournament session again.',
  cancelReg2: 'During the tournament if any one of the participant select another game or leave the tournament, the rankings and winning opportunities will be judged by the number of valid bets and the number of rounds the participants have placed.',
  watch: 'Watch',
  watch1: 'Any player can select the “Watch” option to watch the in progress tournament. Players are not allowed place bets but are able to use the text message function during the watch mode.',
  exception: 'Exception',
  exception1: 'In the event of a server crash, system failure and affect the tournament smooth running, we reserve the right to alter cancellation tournaments and refund all the deducted points or ticket back to every success registered participants.',
  exception2: 'After the tournaments begin, due to the player\'s own network caused by disconnection, the player should be re-login within five (5) rounds and return to the tournament session as soon as you can. During these 5 rounds, auto betting system will be place a minimum amount bet in the Player area. If participant unable to re-login within five (5) rounds will auto eliminated due to participant did not manually place bet with rankings only but with reward opportunity.',
  exception3: 'Any participant in tournament shall be entitled to cancel the qualification and cash award, due to continuous use of betting hedge, AG have rights to blacklist participant account and player will never allowed to participant in any of AG held event or activities.',
  q1: 'How to participate in the Season Tournament?',
  q2: 'Where is the Master Tournament Lobby?',
  q3: 'How to register?',
  q4: 'What is the requirement to participate in the tournament?',
  q5: 'What is the exchange rate for the points?',
  q6: 'Where can I check my current Points balances?',
  q7: 'How to earn more Points?',
  q8: 'What is valid or invalid bet?',
  faq8: 'Valid Bet: refer to all win or loss betting\nInvalid bet: refers to the players in the same game at the same time betting on win and lost two results.\nExample: In Baccarat, player placed bet on “Banker” and “Player” at the same time and same round.',
  q9: 'What are the differences between Satellite Tournament and Master Tournament?',
  faq9: 'Satellite Tournament: Do not have a scheduled start time. A sit and go begins as soon as there are enough people registered at a table. Winner will get a ticket for register in Master tournament.\nMaster Tournament: Master tournament is using Fixed Time mode to begin.',
  q10: 'How much is the registration fee?',
  faq10: 'Within the activity date, all AG real money accounts no need to pay any registration and handling fee. Only when player would like to register for the tournament then require player’s account must have enough points or master tournament ticket(s) for registration.',
  q11: 'How to get the Master Tournament ticket?',
  faq11: 'Make sure you have enough user points to register the Satellite Tournament. And you must won the tournament and get the Master Tournament ticket to participate the Master Tournament.\nRemark: Each participant can save up to maximum 10 Master Tournament tickets. Tickets are valid during the current monthly event for you to join the Master Tournament only. When event end, all remain unused ticket will become invalid and AG will not return or refund for any user points.',
  q12: 'Can I use the user points to exchange the Master Tournament ticket?',
  faq12: 'Yes, only when you are without master tournament ticket but with a lot of user points. Just click the Master Tournament register button and tournament system will direct deduct your user points as stated in the tournament details.\nRemark: Satellite tournament registration is based on your user points to register not ticket.',
  q13: 'Can I decide to choose user points or ticket which one deducts first?',
  faq13: 'For Satellite Tournament will only deduct the user points. For Master Tournament, you can select either deduct user points or master tournament ticket.',
  q14: 'Do Trial play accounts allow for registering for any one of the tournaments?',
  faq14: 'All of our tournaments are only available for AG real money account only. While AG trial plays account are available for watch mode only, i.e. watch the in progress tournament only. If you would like to create an AG real money account, please contact with your customer service representative.',
  q15: 'Why is there a registration failed alert?',
  faq15: 'The following reasons will cause the registration to fail:\n\nYour account is not AG real money account or did not match the tournament’s requirement.\n\nYou were registered before, but you are selected to quit the tournament earlier, so there is a 3 minutes waiting penalty.',
  q16: 'After registration can I cancel?',
  faq16: 'After registration (when the required people is not full) and the tournament has not started:\nAllow players to immediately quit, but within 3 minutes cannot register same type tournament again, the system will return the deducted points or tickets.\nAfter registration (reached the number of entrants) and the tournament has not started:\nParticipant must enter the tournament then quit, and the system will treated participant as eliminated. The system will not return any deducted user points or tickets.\nParticipant take the initiative to retire during the tournament:\nDuring the tournament if any one of the participant select another game or leave the tournament, the rankings and winning opportunities will be judged by the number of valid bets and the number of rounds the participants have placed.\nPlease note:\n1. The same player to sign up for a tournament after quit, will not be able to re-sign the same game.\n2. Each player can only quit the registered tournament within the same day up to 5 times, if more than 5 times is not allowed to register the same type of tournament.',
  q17: 'After registration, do I need to wait in the lobby before tournament starts?',
  faq17: 'There is no need to wait for the tournament start, the player can enter the other games before or near start time, the system will send a message to inform players ready to enter the tournament.',
  q18: 'How long is each tournament?',
  faq18: 'Each tournament is held around 5 to 20 minutes.',
  q19: 'Does the tournament use real money to play?',
  faq19: 'All participants are allowed to use the tournament chips only. The entire tournament chip is not related to the participant real money balances.',
  q20: 'What is the tournament chip?',
  faq20: 'AG Master Tournament adhering to the fair, justice and transparent principle, all participants in the tournament has the same amount of initial chip, known as the tournament chips for rankings determinants. It is not related to participant real money account balance. It is only allowed to use within the tournament. After each section, the tournament chips will be cleared.',
  q21: 'Can spectators bet in the tournament?',
  faq21: 'Only those players who register can play bets on the betting field. The spectators cannot bet and can only watch on the sidelines. If you want to participate, you can press the “register” button in the tournament lobby.',
  q22: 'What does online mean?',
  faq22: 'The total number of players who have registered for the tournament.',
  q23: 'What is the bet limit for each bet area?',
  betLimit: 'Bet Limit',
  bankerPair: 'Banker Pair',
  playerPair: 'Player Pair',
  q24: 'Why are "Pairs" and "Tie" unavailable to bet?',
  faq24: 'All contestants must bet on Banker or Player before able to bet on pair and tie.',
  q25: 'What is Blind?',
  faq25: 'Blind is one of the bet strategy, mainly hidden contestant own placed bet amount. The content of the blind shall be disclosed before completion of the hand.',
  q26: 'What is Pass?',
  faq26: 'Pass is passed current round betting. If you don\'t want to bet the current round, you can just click on “Pass” button.',
  q27: 'Can the time for "Blind" and "Pass" be accumulated?',
  faq27: 'All used or unused cards are valid and only used in tournament only. Once the tournament finish, all remain pass and blind are return to system for the next tournament.',
  q28: 'In Master Tournament, how is promotion/elimination decided?',
  faq28: 'To ensure fairness and transparency among players, Master Tournament will use the following promotion and elimination rules:\nDuring the game, the system adopts the rules set for each match to promote the designated number of players according to the virtual table for each round, and each round of players who have not been promoted will be eliminated automatically. For example: 56 participating in the tournament, the contestants are located in 8 virtual tables (A、B、C、D、E、F、G 、H ) the game is divided into 4 section (each section 5 rounds), then each section the top three contestant promoted (i.e. A to H table, top 3 players promoted to next round until the tournament finish.',
  q29: 'How to determine the player\'s ranking?',
  faq29: 'The ranking rules will use the following order of priority:\nPromoted participants ranked higher than other eliminated participants, then based on the final remain tournament chips to rank.\nIf the participants with the same remaining tournament chips, then the highest wining rate is ranked higher.\nIf there more than one participants with the same rankings in the above two ranking rules, the participants who register earlier will be ranked higher.',
  q30: 'How to distribute the prize or cash?',
  faq30: 'Please click on the “Details” button for information.',
  q31: 'How to receive the prize money obtained in the tournament?',
  faq31: 'All bonuses awarded during the tournament will be credited to the player\'s account immediately.',
  q32: 'Why do other players have more chips than me when the 2nd round started?',
  faq32: 'To increase competitiveness, every players’ chips will be increased before the start of the next round.',
  q33: 'Will I get my points and ticket back if the match is cancelled?',
  faq33: 'Points or tickets will be returned. No deductions.',
  q34: 'Where can I find the betting record?',
  faq34: 'Players can find it with the route: “custom service” > “account record” > then the “match betting record”.',
  q35: 'What is "Top Master"?',
  faq35: 'Top Master is showing the record of each master tournament who rank 1st winner name and the prize award. Click the player’s name will show that player participated in which tournament section and the prize he won.',
  q36: 'What should I do if I am disconnected after the match starts?',
  faq36: 'During the tournament, if your network disconnected and failure, system will automatically bet on the “Player” with the lowest bet limit amount. If player do not return to the same tournament table within 5 rounds, player will be eliminated. Also system will determine player place round and session to judge the ranking and prize award chance.',
  topMasterCongrats: 'Congratulations to the following players for winning the Master Tournament',
  tournamentSetting: 'Tournament Setting',
  others: 'Others',
  regQ3Faq: 'During the tournament, all AG real money accounts do not have to pay any registration and handling fee. When a player would like to register for the Master Tournament, it is required to have enough points or Master Tournament ticket(s).',
  bestTableOwnerTournament: 'BEST TABLE OWNER TOURNAMENT',
  leaderboard: 'LEADERBOARD',
  createYourPrivateTable: 'Create your private table and compete with friends for GENEROUS PRIZES!',
  totalPrize: 'TOTAL PRIZE',
  introDetails: 'During the period from 12:00:00 p.m. on 15th July, 2024 to 11:59:59 a.m. on 20th July (Beijing Time), players will be transported into a baccarat frenzy world! Whether creating their own private tables or joining others\', they can engage in intense battles on the table owner leaderboard. The top private table owners and their players will have the chance to win generous prizes! And if you can guess the strongest table owner, you\'ll receive additional rewards to share the joy with others!',
  step2Details: 'Enter the AG platform and create/join a private table through the event entry in any Baccarat game and place at least one bet to achieve a net win',
  step3Details: 'Eligible players can vote by clicking "Champion Betting" in the event entry and then "Vote Now" or by clicking "Vote" when joining a private table after the end of the first day of the event',
  howRankings: 'How rankings are calculated',
  howRank1: 'The table owner leaderboard ranks based on the total profits earned by private tables during the event period.',
  howRank2: 'If more than one table owner achieves the same total profit, the one with lower net losses will receive a higher ranking.',
  howRank3: 'If multiple table owners achieve the same total profit and net losses, the one who registered earlier will receive a higher ranking.',
  totalProfitMethod: 'Total profit calculation method',
  totalProfit1: 'In a private baccarat table, both the table owner and other participants will have their net winnings calculated toward the player profit contribution if they place at least one bet and achieve a net profit.',
  totalProfit2: 'The total sum of profits contributed by all players in a private table constitutes the total profit of that private table.',
  totalProfit3: 'The players\' contributed profits and total profit do not take into account the players\' net losses in Baccarat rounds.',
  totalProfit4: 'The players\' contributed profits and total profits are displayed in Chinese Yuan (CNY). If players use other currencies for betting, the system will convert them using the provided exchange rate.',
  totalProfit5: 'Example: During the event period, a player opens a private table, bets 20 CNY on "Tie", and wins on "Tie". The net winnings are (20x8) = 160 CNY. In this round, 160 CNY will be calculated as the player\'s contributed profit and total profit.',
  tableOwnerPrizeCalc: 'Table Owner Leaderboard Prize Calculation',
  tableOwner1: 'Table owners ranked in the top 20 of the leaderboard are eligible to receive the table owner prize.',
  tableOwner2: 'If the table owner that a participant has joined ranks in the top 20, all participants of that private table can share an equivalent additional prize from the table owner leaderboard.',
  tableOwner3: 'Participants share the additional prize based on the proportion of their contributed profits to the total profit.',
  tableOwner4: 'If the participant\'s share of the prize is less than 1 CNY after proportional distribution, the system will send 1 CNY to the participant.',
  example: 'Example',
  tableOwner5: 'In player A\'s private table, the total profit is 250 CNY, with player A contributing 200 CNY and player B contributing 50 CNY.',
  tableOwner6: 'Player A wins first place on the table owner leaderboard and receives a prize of 10,000 CNY.',
  tableOwner7: 'Player A\'s total prize: 10,000 (table owner prize) + 10,000 (participant prize) x 200/250 (Player A\'s contributed profit / total profit) = 18,000 CNY',
  tableOwner8: 'Player B\'s total prize: 10,000 (participant prize) x 50/250 (Player B\'s contributed profit / total profit) = 2,000 CNY',
  tableLeaderboardPrize: 'Table Owner Leaderboard Prize (CNY)',
  championshipRules: 'Championship Betting Event Rules',
  eligibility: 'Eligibility to Participate',
  eligibility1: 'Players need to achieve VIP 1 and have at least 1,500 AG points.',
  htp: 'How to participate',
  htp1: 'Enter the "Championship Betting Event" portal between 12:00:00 p.m. on 16th July, 2024 to 11:29:59 a.m. on 20th July (Beijing Time) to cast your vote.',
  htp2: 'Players need to pay 1,500 AG points to vote.',
  howToGetPrizes: 'How to Get Prizes',
  howToGetPrizes1: 'At the end of the event, if the table owner voted by players ranks in the top three, the player will receive corresponding prizes.',
  votingRestrictions: 'Voting Restrictions',
  vr1: 'Each player can only vote for one table owner during the event.',
  vr2: 'Once confirmed, voting records cannot be modified.',
  vr3: 'Each table owner can only receive votes from up to 500 players. If the maximum number of votes is reached, players will be unable to vote for that table owner.',
  bettingPrize: 'Betting Prize (CNY)',
  betPrize1: '1st place: 188',
  betPrize2: '2nd place: 128',
  betPrize3: '3rd place: 88',
  ntc1: '1. The Best Table Owner Tournament is only applicable to AG Baccarat video games.',
  ntc2: '2. By participating in this AG event, players are deemed to agree to the terms and conditions of this event.',
  ntc3: '3. During the event period, players participating in the "Championship Betting Event" must use their existing user points for the event.',
  ntc4: '4. User point earnings are limited to valid bets placed on the AG platform. For detailed explanations and point redemption rules, please refer to the "User Points System" instructions.',
  ntc5: '5. To ensure the broad participation of members in the event, each member is limited to one account and one IP address. Violators will have their prize qualification revoked, and serious offenders may be banned or have their related profits deducted by AG without prior notice.',
  ntc6: '6. AG reserves the right to ban or deduct profits from any member who participates in the event through any illegal means, including but not limited to collusion, cheating, etc., without prior notice.',
  ntc7: '7. The platform reserves the right to execute, modify, interpret, and terminate the event.',
  ntc8: '8. The players\' contributed profits and total profit do not include payouts from any Jackpot.',
  nFaq1: 'Where is the event entrance located?',
  ansNFaq1: 'Once you successfully enter any Baccarat table, you can find the event entrance in the table interface.',
  nFaq2: 'How to participate in the Best Table Owner Tournament?',
  ansNFaq2: 'After entering the event entrance, click on "Create Private Table" or join private table and gain at least one round of net wins and participant the event.',
  nFaq3: 'Can I be both a table owner and a participant?',
  ansNFaq3: 'Yes, players can be both a table owner and a participant. The net wins earned in your private table will be counted towards the player\'s contributed prize. If your private table wins a prize, you will receive both the table owner and participant prizes.',
  nFaq4: 'Can I join more than one private table during the event?',
  ansNFaq4: 'During the event period, players can join multiple private tables. If all the private tables a player participates in are ranked among the top 20 table owners, the player will receive participant pirzes from these top 20 private tables.',
  nFaq5: 'Does the table owner leaderboard use personal real money balances for betting?',
  ansNFaq5: 'During the event, the amount wagered on private tables is deducted from your personal real money balance.',
  nFaq6: 'How are total profits and player contribution profits calculated?',
  ansNFaq6: 'The net winnings (excluding the bet principal) that players achieve when placing bets at a private baccarat table will be calculated in the player\'s contributed profit and total profit on the player page.',
  nFaq7: 'Will total profits be calculated separately after exiting and rejoining a private table?',
  ansNFaq7: 'If you exit a private table and rejoin or create a new one, the profits earned will be accumulated and calculated under the same table owner name.',
  nFaq8: 'How do I invite players to my private table?',
  ansNFaq8: 'After successfully creating a public private table, there will be a 5-second prompt allowing the table owner to send a public invitation. Clicking this will send an invitation message in the chat. Players can click the invitation message to request entry to the private table. The table owner can also send an invitation message via the "Public Invitation" button in the chat interface.',
  nFaq9: 'How to participate in the Championship Betting Event?',
  ansNFaq9: 'Eligible players can vote by clicking "Champion Betting" in the event entry and then "Vote Now" or by clicking "Vote" when joining a private table after the end of the first day of the event.',
  nFaq10: 'Are there any restrictions on voting?',
  ansNFaq10: 'During the voting process, players must reach VIP 5 and pay 1,500 AG points to vote. Each player can only vote for one table owner during the event. Once confirmed, voting records cannot be modified. Each table owner can only receive votes from up to 500 players. If the maximum number of votes is reached, players will be unable to vote for that table owner. If all table owners reach the maximum vote limit, players cannot participate in the event.',
  nFaq11: 'How to claim prizes in the Best Table Owner Tournament?',
  ansNFaq11: 'All prizes earned during the event will be instantly credited to the player\'s account after verification. Details can be viewed in the credit records.',
  congratsTopTable: 'Congratulations to the following players for becoming the top table owner!',
  actualBonus: 'Actual bonus',
  miCardBaccaratTitle: 'MiCard Baccarat',
  miCardBaccarat: 'MiCard Baccarat - Spin to Win',
  spinForFortune: 'Spin for Fortune, Win Big Prizes',
  topPrizePart1: 'Top Prize of',
  prize: 'CNY 150,000',
  topPrizePart2: 'Awaits You!',
  topPrize: 'Top Prize of CNY 150,000 Awaits You!',
  eventOverview: 'Event Overview',
  eventOverviewDetails: 'The brand-new MiCard Baccarat game will host a grand promotional event from 28-29/10/2024, inviting all players to participate! During the event, the MiCard Baccarat table will feature 12 exciting prize draws. Over these two days, whether you are a Seated Player or a Side Betting Player, you can interact in real-time with charming hosts and enjoy an unprecedented gaming experience. Regardless of winning or losing, all participants have the chance to win generous prizes. Don\'t miss this rare opportunity—join us and experience this unmatched celebration of rewards!',
  howToParticipate: 'How to Participate',
  howTo1: 'Users must have a real-money account on the AG platform.',
  howTo2: 'Players must place valid bets of at least CNY 1,000 in MiCard Baccarat within the designated time before the event.',
  howTo3: 'Players must enter the MiCard Baccarat table before the event starts; the prize draw will be held at the end of a randomly selected shoe.',
  howTo4: 'When the event begins, you must click "Join Now" on the event interface to confirm your participation.',
  eventDetails: 'Event Details',
  eventRules: 'Event Rules',
  howToQualify: 'How to Qualify',
  howToQualify1: 'Players must place valid bets of at least CNY 1,000 in MiCard Baccarat within the designated time before the event to automatically qualify.',
  howToQualify2: 'Players must enter the MiCard Baccarat table before the event starts. (A notification will appear on the event icon before the end of the preceding shoe.)',
  howToQualify3: 'When the event begins, you must click "Join Now" on the event interface to confirm your participation.',
  howToQualify4: 'If a player fails to meet the betting threshold within the designated time before the event or does not click "Join Now" on the event interface, the platform will consider the player to have forfeited participation and will automatically set them to observer mode when the event starts.',
  eventTime: 'Event Time',
  eventTimeDetails: '28/10/2024 to 29/10/2024, from 15:00 to 23:00 (Beijing Time).',
  eventSessions: '6 sessions per day, totaling 12 sessions over two days.',
  howItWorks: 'How It Works',
  howItWorks1: 'Players must enter the MiCard Baccarat table before the event starts; the prize draw will be held at the end of a randomly selected shoe.',
  howItWorks2: 'Players choose one of four prizes, after which the host spins the wheel. If the player does not choose a prize, the system will automatically select one at random.',
  howItWorks3: 'If the selected prize matches the wheel\'s result, all winning players share the prize pool; if not, they can still share a participation prize pool of CNY 20,000. If the shared amount is less than CNY 1, the system will automatically give CNY 1 to each winning player.',
  prizePools: 'Prize Pools (CNY)',
  wheelPrizes: 'Wheel Prizes: 150,000, 120,000, 100,000, 80,000',
  participationPrize: 'Participation Prizes: 20,000',
  eventTerms: 'Event Terms',
  eventTerms1: 'Spin to Win is only available for AG’s MiCard Baccarat video game.',
  eventTerms2: 'By participating in this AG event, players agree to the event terms.',
  eventTerms3: 'Actual prizes will be calculated based on the exchange rate at the time and shared among other winners who chose the same prize pool.',
  eventTerms4: 'To ensure the event benefits all members, each member is limited to participating with one account and one IP address. Violators will be disqualified, and in serious cases of fraud, AG reserves the right to block accounts or deduct related profits without prior notice.',
  eventTerms5: 'If any member participates in this event using any illegal means, including but not limited to using multiple accounts, AG reserves the right to block accounts or deduct related profits without prior notice.',
  eventTerms6: 'The platform reserves the right to enforce, modify, interpret, and terminate the event.',
  faqs: 'FAQs',
  faq1: 'How can I check the details of the Spin to Win event?',
  a1Method1: 'Method 1: Click on the event banner in the MiCard Baccarat game lobby to view event information.',
  a1Method2: 'Method 2: After successfully entering the MiCard Baccarat room, click on the event icon in the room interface to view event information.',
  faq2: 'How do I participate in the Spin to Win?',
  a2: 'Players must place valid bets of at least CNY 1,000 in MiCard Baccarat within the designated time before the event and enter the MiCard Baccarat table before the event starts. When the event begins, please click "Join Now" on the interface to confirm your participation.',
  faq3: 'How much prize money will each qualifying player receive?',
  a3: 'The prize amount depends on the result of the wheel spin. The final prize for each event will be shared among all players who correctly guessed the wheel result, while those who did not guess correctly will share the participation prize pool.',
  faq4: 'Can I participate in more than one event and qualify each time?',
  a4: 'Yes. Before each event, you must reach the minimum bet amount of CNY 1,000 within the specified time to be eligible for that event. After each event, your qualifying bet amount will reset.\nExample: If you reach the minimum bet amount of CNY 1,000 before each event, you can participate in all event sessions.',
  faq5: 'Is there a participant limit for the event?',
  a5: 'There is no participant limit for this event; players who successfully enter the event table and meet the minimum bet amount can participate.',
  faq6: 'If I disconnect during the event, can I still participate and win prizes?',
  a6: 'Players must click "Join Now" when the event begins to confirm their participation. If you disconnect after confirming, you will still be eligible to win prizes.',
  faq7: 'How do I claim the prizes won in the Spin to Win?',
  a7: 'Event prizes will be distributed after the wheel spin at the end of each session. All prizes won during the event will be credited to players\' accounts immediately after verification, and details can be viewed in the transaction record.'
};
