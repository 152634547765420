export const cnTraditional = {
  gog: '賭神賽',
  stayTuned: '敬請期待',
  introduction: '巔峰賽簡介',
  award: '獎金',
  details: '巔峰賽詳情',
  faq: '常見問題',
  new: '新',
  topMaster: '賭神榜',
  backToTop: '返回頁頂',
  introductionDetails: 'AG賭神賽基於最受歡迎的百家樂遊戲，\n迎合當前網絡最火爆的坐滿即玩比賽方式，\n以公平透明的比賽規則為玩家呈現最具娛樂性的線上百家樂比賽。',
  introductionDetailsMobile: 'AG賭神賽基於最受歡迎的\n百家樂遊戲，迎合當前\n網絡最火爆的坐滿即玩比賽方式，\n以公平透明的比賽規則為玩家呈現\n最具娛樂性的線上百家樂比賽。',
  howToEnter: '如何參加',
  gameProcess: '比赛流程',
  step: '步驟',
  step1: '用戶賬號必須為\nAG平台的真錢賬號',
  step2: '進入AG平台\n在 “賭神賽” 廳中選擇\n對應的比賽場報名即可',
  howToJoin1: '用戶賬號必須為AG平台的真錢賬號',
  howToJoin2: '進入AG平臺在“賭神賽”廳中選擇對應的比賽場報名即可',
  satelliteTournament: '採用人滿開賽的方式，資格賽獲勝玩家將獲得賭神賽門票，於賭神賽贏取更豐富獎金。',
  masterTournament: '採用定時開賽的方式，讓更多玩家都能參與到比賽中來，同時賭神賽的巨額獎金使比賽競爭更激烈，比賽氣氛更緊張刺激。',
  seasonTournament: '採用定時開賽方式，邀請當季賭神參賽，與通過足額積分報名的玩家進行高手同場競技，冠軍將獲得超高額獎金！',
  awardDetails: '所有用戶在所參與的比賽場中所獲得的獎金會即時到賬用戶所在的AG遊戲戶口，無需等待，無需審核。',
  qualifyingRound: '資格賽',
  qualRoundText: '採用人滿開賽的方式，資格賽\n獲勝玩家獲得賭神賽門票，\n於賭神賽贏取更豐富獎金',
  gogTickets: '賭神賽門票 /\n足額積分',
  gogMasterTournament: '賭神賽',
  gogStep3: '採用定時開賽的方式，讓更多\n玩家都能參與到比賽中來。\n同時賭神賽的巨額獎金使比賽\n競爭更激烈，氣氛更緊張刺激。',
  tournamentRules: '巔峰賽規則',
  fairness: '公平性',
  regAndWithdrawal: '報名及退賽',
  inGame: '玩法規則',
  ranking: '排名規則',
  withdraw3: '報名後退賽（未滿足所需人員時）且比賽尚未開始',
  withdraw3Child: '玩家可以立即退出，3分鐘內玩家不能再次報名同類型賽事，系統將退還扣除的積分/門票。',
  withdraw4: '報名後退賽（達到報名人數）且比賽尚未開始：',
  withdraw4Child: '參賽者必須進入比賽方可退出，該參賽者將被系統視為淘汰。 所有扣除的用戶積分或門票將不予退還。',
  withdraw5: '參賽者決定在比賽期間退賽',
  withdraw5Child: '比賽期間，若任何參賽者選擇另一場比賽或退出比賽，則根據有效投注數和參賽輪數來決定排名和獲勝機會。',
  withdraw6: '玩家退出該場比賽後將無法重新參與同一場比賽。',
  withdraw7: '每位玩家在同一天內只能退出已報名的比賽最多5次，如果超過5次則不允許玩家報名同一類型的比賽。',
  tournamentRules1: '玩家報名參加比賽後，在比賽中將以固定的比賽籌碼在特定的比賽桌枱中進行投注，該比賽籌碼與玩家個人真錢額度及 用戶積分並無任何關聯，所有比賽籌碼將不會累積帶到下一個比賽場中。若玩家的用戶積分不足，可通過非比賽的遊戲投注，來提高有效投注額換積分；',
  tournamentRules2: '為保證系統公平透明性，玩家報名成功後，進入比賽場時的座位均採用系統隨機分配，玩家不得選擇或交換桌台上的座位；',
  tournamentRules3: '所有比賽獎金將依照各比賽場獎金分配表發放，實時到賬；',
  tournamentRules4: '比賽將根據“免佣百家樂”的規則下進行，有關“百家樂”遊戲規則請於遊戲內按菜單>遊戲規則查看玩法。參賽者必須於每局下注時間內下注，每局的下注時限為25 秒，每位參賽者必須於每局至少以最低投注額下注於莊家或閒家。於每一手牌中，參賽者只可選擇投注於莊家或閒家任何一方，當選擇投注於和局或對子時，亦必須同時選擇投注莊家或閒家其中一方；',
  tournamentRules5Part1: '於資格賽及賭神賽開始前，每位參賽者將獲得比賽籌碼10萬（比賽籌碼只適用於比賽，不能兌換現金或現金籌碼），玩家在比賽中的投注限紅為1000 -10,000，參賽者不可投注多於其擁有之比賽籌碼。',
  tournamentRules5Part2: '在比賽進行時，參賽者於每局的投注金額限制如下：',
  betItem: '投注項目',
  minBet: '最低投注籌碼',
  maxBet: '最高投注籌碼',
  banker: '莊家',
  player: '閒家',
  tie: '和局',
  pair: '對子',
  tournamentRules6: '所有比賽場，荷官會先派出2局不設投注的牌局後開始正式發牌，後面的比賽在同一靴牌中將不再重復洗牌；',
  tournamentRules7: '為保證玩家間的公平透明，比賽系統將使用以下晉級/淘汰機制：',
  tournamentRules7_1: '比賽過程中,系統根據每場比賽場次設定的規則每輪按虛擬桌晉級指定數目的玩家，每輪未獲晉級的參賽者將自動淘汰；',
  tournamentRules7_2: '例如：某場比賽共56人參賽，玩家分布於“A、B、C、D、E、F、G 、H”8個虛擬桌上，比賽共分4輪(每輪5局)進行，每輪比賽每桌晉級前3名, 即A-H每桌的前3名晉級下一輪(未晉級的自動淘汰),直到比賽完成。',
  tournamentRules7_3: '玩家比賽中晉級的額度優勢：如比賽場設定每桌每輪晉級2 人以上時，當玩家在上一輪以第一名晉級下一輪時，每桌第一名的玩家的在下一輪的初始比賽籌碼額度會有固定百分比的增加（即每桌的第一名會比其他玩家多固定百分比的比賽籌碼額度，該百分比在游戲中設定）',
  tournamentRules7_4: '系統採用盡量保證每桌玩家數目平均的方式，每輪晉級的玩家將會再次隨機平均分配到不同的桌上進行遊戲；',
  tournamentRules8: '於比賽過程中，可使用的特別投注功能條款如下:',
  tournamentRules8_1: '“暗注”供參賽者於比賽中使用，是屬於策略投注技巧。主要是隱藏自己投注的項目，直到投注時間完畢時，其他玩家才能看到自己所投的籌碼及位置。暗注次數則按該場比賽的”輪數”提供相對的暗注次數。例如：總輪數：5 , 總暗注次數：5',
  tournamentRules8_2: '“過牌”供參賽者做決定時使用，意思就是不下注。過牌次數則按該場比賽的”輪數”提供相對的過牌次數。例如：總輪數：5 , 總過牌次數：5',
  tournamentRules9: '不論贏或輸，所有已使用或未使用的特別投注功能於比賽結束時將自動清零，參賽者之間不能轉讓或借用比賽籌碼，在每場比賽結束後，系統會將玩家的比賽籌碼自動清零。',
  tournamentRules10: '排名規則將使用以下優先級：',
  tournamentRules10_1: '晉級玩家排名高於淘汰玩家,然後按該場比賽籌碼從高至低來排名；',
  tournamentRules10_2: '若玩家比賽籌碼相同,則該場勝率較高者排名較高；',
  tournamentRules10_3: '若以上2个排名規則情况下多個參賽者存在相同排名,則以該場報名時間較早的玩家排名較高。',
  tournamentRules11: '如參賽者在比賽中因任何理由需離開比賽，離桌的時間不可超過5局遊戲，若違反此規定，参賽者獲獎的資格將被取消而只可獲排名。',
  tc: '活動條款',
  tc1: '所有有效AG真錢會員均可參加。',
  tc2: '活動期間，玩家參與“資格賽”，需使用玩家現有用戶積分報名參與賽事；',
  tc3: '所有比賽均依照比賽大廳所公告之時間准時開始報名，我們有權在不事先通知玩家的情況下，更改、延遲或取消比賽。',
  tc4: '用戶積分賺取只限於AG平台中進行有效投注，詳細說明以及積分兌換規則請參考“用戶積分系統” 說明。',
  tc5: '“資格賽”勝出者即可獲取參與“賭神賽”門票一張(若門票已派完則當日不再發放門票，所有比賽也不再接受報名，先到先得，派完即止)，每位玩家獲得的門票於本月比賽活動期間有效；',
  tc6: '為保證活動回饋廣大會員，本活動每位會員限同一帳戶及同一IP地址參加。違規者取消領獎資格，嚴重舞弊者AG有權在不事先通知情況下作封號處理或扣除相關獲利；',
  tc7: '任何會員以任何非法手段，包括但不限於對衝、合謀作弊等參加本活動，違規處理等同活動條款 6；',
  tc8: '若違反比賽之規定，參賽者將會失去其參賽的資格;',
  tc9: '玩家參加本次AG活動，即視為同意本活動條款；',
  tc10: 'AG保留對本次活動的最終解釋權。',
  remark: '注意事項',
  registration: '報名',
  registration1: '如果玩家同時擁有賭神賽門票和足夠積分的情況報名賭神賽, 玩家可選擇使用積分或賭神賽門票參賽。',
  registration2: '任何比賽開始前的20分鐘內同一用戶同時只能報名一場比賽（資格賽或賭神賽）',
  registration2_1: '為確保玩家能優先進行賭神賽，當玩家已經報名賭神賽且距離下場賭神賽開始只有20分鐘的情況下，同一個玩家不能報名另一場資格賽；',
  registration2_2: '若玩家已報名賭神賽且離該賭神賽開始多於20分鐘，則玩家仍可以報名資格賽，但當該場賭神賽開始只有20分鐘且玩家報名的資格賽仍未開始則系統會自動取消玩家所報名的資格賽並退回積分。',
  cancelReg: '退賽',
  cancelReg1: '每一位玩家只可以在比赛开始前退赛，退赛后3分鐘內将不可以重新报名该相同场次的比赛, 系統會退回所扣積分或門票。',
  cancelReg2: '比賽開始後,玩家強行退賽將按該玩家已下注局數及輪數來判斷排名及獲獎機會。',
  watch: '旁觀',
  watch1: '所有用戶可以選擇旁觀正在進行中的比賽, 不能投注比賽, 但可以進行聊天;',
  exception: '異常處理',
  exception1: '如遇系統故障原因而影響比賽順利進行,我們有權隨時取消比賽，並且將該場比賽取消後實時退還玩家在該場比賽已扣除的用戶積分或賭神賽門票；',
  exception2: '比賽開始後，因玩家自身網絡原因造成的斷線，玩家應在5局時間內重新登錄並即時返回該比賽場遊戲，自動下注系統將在每一局以最小限紅於“閒”下注，當5局時間內玩家仍未進入比賽場將自動因玩家沒有任何手動下注而被淘汰，玩家只會獲取排名而不會獲取任何獎金機會；',
  exception3: '任何參與比賽的玩家如因連續使用對衝下注等方法進行比賽投注，我們有權取消玩家比賽資格及獎金，並將對該玩家作封號處理，永久不得參加比賽。',
  q1: '如何參加季度賽？',
  q2: '比賽大廳在哪？',
  q3: '如何報名比賽？',
  q4: '參賽資格是甚麼？',
  q5: '如何兌換積分？',
  q6: '在哪查詢我有多少積分？',
  q7: '怎樣賺取更多積分？',
  q8: '怎樣才算有效投注？',
  faq8: '有效投注: 指所有產生輸贏的投注。\n無效投注: 指玩家在同一局遊戲同時投注輸贏兩種結果。\n例：在百家樂遊戲同一局內，同時下注莊、閒的對沖投注。',
  q9: '資格賽和賭神賽有甚麼區別？',
  faq9: '資格賽: 採用人滿開賽的方式，資格賽獲勝玩家將獲得賭神賽門票，於賭神賽贏取更豐富獎金。\n賭神賽：採用定時開賽的方式，讓更多玩家都能參與到比賽中來，同時賭神賽的巨額獎金使比賽競爭更激烈，比賽氣氛更緊張刺激。',
  q10: '報名費是多少？',
  faq10: '活動期間玩家無需任何報名及手續費用，只需使用用戶積分或賭神賽門票進行報名。',
  q11: '賭神賽門票如何獲得？',
  faq11: '在同一天內，只要您有足夠的用戶積分，便可無限次報名資格賽。資格賽獲勝玩家將獲得賭神賽門票，憑此門票可參加於活動期間舉辦的任何賭神賽場次。\n注意：每一玩家擁有的賭神賽門票數量上限為10張，獲得的門票可於活動期間用於報名的賭神賽，報名一場賭神賽即立刻扣除玩家一張門票。賭神賽門票只於賭神賽本月比賽活動期間有效，活動結束後即失效，AG不作任何退款及退積分。',
  q12: '能否直接使用用戶積分兌換賭神賽門票參賽？',
  faq12: '可以，如果您沒有賭神賽門票但擁有很多的用戶積分情況下，您可直接在賭神賽按報名鍵，系統會直接扣除所需積分。\n請注意：資格賽只可使用用戶積分進行報名',
  q13: '可以選擇扣除門票或積分嗎？',
  faq13: '資格賽報名時, 只扣除玩家的用戶積分；賭神賽報名時，則可選擇使用積分或賭神賽門票參賽。',
  q14: '試玩帳號能否報名？',
  faq14: '活動期間所有比賽場只開放給AG真錢賬號報名，試玩賬號的玩家只限進入任何比賽進行旁觀。',
  q15: '為甚麼提示報名失敗？',
  faq15: '報名失敗有以下幾種原因：\n您的賬戶不是真錢帳戶或沒有符合參賽要求；\n您已報名並選擇退賽，於3分鐘內您將不允許再次報名同一比賽場次。',
  q16: '報名後能否退賽？',
  faq16: '報名後(人數未滿時)及比賽未開始：\n允許玩家即時退賽，但3分鐘內不能再次報名同類型的比賽，系統會退回所扣積分或門票。\n報名後(人數已達到要求)及比賽未開始：\n允許玩家進入比賽後才能退賽，系統將判斷玩家為自動棄權，因玩家主動退賽，系統不會退回因報名已扣除的積分或門票。\n比賽中玩家主動退賽：\n玩家在比赛中主动点”返回”来退赛，系统将判断玩家已下注的局数及轮数来判断玩家的排名及获奖机会，另外因玩家主动退赛，系统不会退回因报名已扣除的积分或门票。\n請注意：\n1. 同一玩家報名一場比賽後選擇退賽，將不可重新報名同一場次的比賽；\n2. 每名玩家同一天內最多只可退賽5次，若超過5次則不允許報名同類型比賽。',
  q17: '報名後，是否須在比賽大廳等待開賽？',
  faq17: '無須在比賽大廳等待開賽，玩家可以先進入其他遊戲下注，直到人滿比賽開始前或是臨近開賽時間時，系統將會發出提示信息通知玩家準備進入比賽。',
  q18: '每場比賽需時多久？',
  faq18: '每一場比賽大概5至20分鐘。',
  q19: '比賽是否使用個人真錢額度投注？',
  faq19: '在比賽場下注時，所有參賽者都只能使用系統提供的比賽籌碼投注，與玩家本身的真錢額度沒有直接關係。',
  q20: '比賽籌碼是甚麼？',
  faq20: 'AG賭神賽秉承公平、公正、透明的原則，比賽場中所有玩家進入比賽時都擁有相同的初始籌碼，即稱為比賽籌碼。比賽籌碼為AG賭神賽系統中專用的額度，與玩家本身的真錢額度沒有直接關系，比賽籌碼的多少將作為比賽排名的決定因素。比賽籌碼不能轉入及轉出，只可用於當場比賽，每場比賽結束後，參賽者的比賽籌碼即被清零。',
  q21: '旁觀可以投注嗎？',
  faq21: '只有報名參賽的玩家才可於比賽場內進行投注，旁觀的玩家不可進行投注，只可進行旁觀。如須參賽，可在比賽大廳按“報名參賽”鍵，選擇比賽場進行報名。',
  q22: '比賽中的在線人數是什麼？',
  faq22: '代表已報名該場比賽的總人數。',
  q23: '各投注區的限紅是多少？',
  betLimit: '限紅',
  bankerPair: '莊對子',
  playerPair: '閒對子',
  q24: '為何無法投注“對子”及“和”？',
  faq24: '參賽者必須投注莊或閒家其中一方，才可投注“對子”及“和”。',
  q25: '甚麼是“暗注”？',
  faq25: '玩家先按下“暗注”鍵，再進行投注，將會隱藏玩家投注的項目和金額，直到開牌時，其他玩家才能看到玩家所投的籌碼，屬於一種策略投注技巧。',
  q26: '甚麼是“過牌”？',
  faq26: '玩家按下“過牌”後，該局可不進行任何投注，系統也不會自動扣除最小限紅的投注額。',
  q27: '過牌及暗注次數是否能累計？',
  faq27: '所有已使用或未使用的過牌及暗注次數只於該場比賽內有效，該場比賽結束後即失效，無法累計到下一場使用。',
  q28: '於賭神賽中，如何決定晉級或淘汰？',
  faq28: '為保證比賽的公平透明， 賭神賽 系統將使用以下晉級及淘汰機制：\n比賽過程中，每輪比賽每張虛擬桌晉級固定數目的玩家，每輪未獲晉級的玩家將自動淘汰。例如：某場比賽共56人參賽，玩家分佈於A、B、C、D、E、F、G 、H 八個虛擬桌上，比賽共分4輪(每輪5局)進行，每輪比賽每桌晉級前3名, 即A-H每桌的前3名晉級下一輪(未晉級的自動淘汰),直到比賽完成。\n',
  q29: '如何決定玩家排名？',
  faq29: '排名規則將使用以下優先順序：\n晉級玩家排名高於淘汰玩家，然後按該場比賽籌碼從高至低來排名；\n若玩家比賽籌碼相同,則該場勝率較高者排名較高；\n若以上2個排名規則情況下多個玩家存在相同排名,則以該場報名時間較早的玩家排名較高。',
  q30: '獎品或獎金如何分配？',
  faq30: '請於該比賽場按“查看詳情”鍵查詢有關資訊。',
  q31: '怎樣領取在比賽中獲得的獎金？',
  faq31: '在比賽中所有獲得的獎金經審核後將即時存入玩家帳戶內。',
  q32: '為甚麼賭神賽中下一輪開始時其他人的比賽籌碼比我高？',
  faq32: '所有參賽者於賭神賽晉級後，直到該場比賽完結。為了保持玩家與玩家的良性競爭，每桌第一名的玩家在下一輪的初始比賽籌碼額度會有固定百分比的增加。',
  q33: '如比賽取消已扣除的積分或賭神賽門票是否會退回？',
  faq33: '如比賽取消，所有已扣除的積分或門票都會即時退回到您的真錢帳號內，無需扣除任何手續費。',
  q34: '比賽投注紀錄在哪查詢？',
  faq34: '參賽者可在比賽大廳按“客戶服務” > “帳戶紀錄” > 於左側選擇“比賽下注紀錄”內查詢。',
  q35: '賭神榜是什麼?',
  faq35: '賭神榜是紀錄每一場賭神賽獲得第1名的賭神名稱及獲得的獎金金額，按下排名中玩家的名字可查看該玩家於賭神賽中獲得獎金的金額及場次詳情。',
  q36: '賭神賽進行過程中，我的網絡掉線了怎麼辦？',
  faq36: '若玩家因網路掉線，系統將自動在5局內以最少注於“閒”下注，5局內未返回比賽場則當作淘汰處理。系統會根據玩家曾經下注的局數及輪數來判斷玩家排名位置及獲獎機會。',
  topMasterCongrats: '恭喜以下玩家於賭神賽奪獎',
  tournamentSetting: '賽事設置',
  others: '其他',
  regQ3Faq: '比賽期間，所有AG真錢賬戶無需支付任何註冊費和手續費。 當玩家想要報名參加賭神賽時，其賬戶中需要有足夠的積分或賭神賽門票。',
  bestTableOwnerTournament: '最強桌主巔峰賽',
  leaderboard: '桌主榜',
  createYourPrivateTable: '打造您的私人桌，與朋友一同爭奪豐厚獎金！',
  totalPrize: '總獎金',
  introDetails: '在北京時間2024年7月15日12時00分00秒至2024年7月20日11時59分59秒期間，玩家們將被帶入一個百家樂的狂歡世界！無論是創建自己的私人桌還是加入其他人的，都能參與桌主排行榜的激烈對決。最頂尖的私人桌桌主和他們的玩家將有機會贏得豐厚的獎金！而如果你能猜中最強桌主，你將獲得額外的獎金，與他人分享這份喜悅！',
  step2Details: '進入AG平台後在任意百家樂中的活動入口創建/加入私人桌後下注最少一局獲得淨贏即可',
  step3Details: '符合資格的玩家在活動第一天完結後，在任意百家樂中的活動入口中點擊 “冠軍競猜“ 點擊 “立即投票“ 或在加入私人桌時點擊 “投票“ 即可進行投票。',
  howRankings: '如何計算排名',
  howRank1: '桌主排行榜以私人桌在活動期間獲得的總盈利計算排名。',
  howRank2: '若多於一個桌主獲得相同的總盈利，獲得較低的淨輸金額會獲得較高的排名。',
  howRank3: '若多於一個桌主獲得相同的總盈利及總淨輸金額，較早注冊的桌主玩家可以獲得較高的排名。',
  totalProfitMethod: '總盈利計算方式',
  totalProfit1: '在私人桌中，桌主及其他參與者在百家樂私人桌中下注最少一局並獲得淨贏利會計算於玩家盈利貢獻中。',
  totalProfit2: '在私人桌中所有玩家盈利貢獻的總和為私人桌總盈利。',
  totalProfit3: '玩家盈利貢獻及總盈利不會計算玩家在百家樂回合中淨輸金額。',
  totalProfit4: '玩家盈利貢獻及總盈利以人民幣方式顯示，如玩家使用其他幣種進行下注將以系統提供匯率進行換算。',
  totalProfit5: '例子：玩家在活動期間開啟私人桌，下注“和“20 人民幣，並以“和“勝出，淨贏為 (20x8) = 160 人民幣。在當局會計算 160 人民幣於玩家盈利貢獻及總盈利中。',
  tableOwnerPrizeCalc: '桌主排行榜獎金計算',
  tableOwner1: '桌主於排行榜獲得最高20排名可獲得桌主獎金。',
  tableOwner2: '參與者所參加的桌主若在最高20排名中，所有該私人桌的參與者可瓜分等值桌主於桌主排行榜的額外獎金。',
  tableOwner3: '參與者以玩家盈利貢獻於總盈利的佔比瓜分額外獎金。',
  tableOwner4: '若參與者獲得的獎金按佔比瓜分後不足 1 人民幣，系統會發送 1 人民幣給參與者。',
  example: '例子',
  tableOwner5: '在玩家 A  的私人桌中的總盈利為 250 人民幣，其中玩家 A 的玩家盈利貢獻為 200 人民幣；玩家 B 的玩家貢獻盈利為 50 人民幣。',
  tableOwner6: '玩家 A 在桌主排行榜奪得第一名，獲得10,000 人民幣獎金',
  tableOwner7: '玩家 A 的總獎金： 10000 （桌主獎金）+ 10000 （參與者獎金）x 200/250 （玩家 A 盈利貢獻／總盈利）= 18000 人民幣',
  tableOwner8: '玩家 B 的總獎金： 10000 （參與者獎金）x 50/250 （玩家 B 盈利貢獻／總盈利）= 2000 人民幣',
  tableLeaderboardPrize: '桌主獎金 (CNY)',
  championshipRules: '冠軍競猜活動規則',
  eligibility: '參與資格',
  eligibility1: '玩家需要達到VIP 1 及最少 1,500 AG積分',
  htp: '如何參與',
  htp1: '在北京時間2024年7月16日12時00分00秒至2024年7月20日11時29分59秒期間進入「冠軍競猜活動」入口進行投票。',
  htp2: '玩家需消耗 1,500 AG積分進行投票。',
  howToGetPrizes: '如何獲得獎金',
  howToGetPrizes1: '活動結束時，如果玩家投票的桌主在最終排名中獲得前三名，玩家將獲得相應的獎金。 ',
  votingRestrictions: '投票限制',
  vr1: '每名玩家只能在活動期間投票一名桌主。',
  vr2: '確認投票後不能修改投票記錄。',
  vr3: '每位桌主只能接受500名玩家的投票。如果投票人數已達上限，玩家將無法為該桌主投票。',
  bettingPrize: '競猜獎金 (CNY)',
  betPrize1: '第1名：188',
  betPrize2: '第2名：128',
  betPrize3: '第3名：88',
  ntc1: '1. 最強桌主巔峰賽僅支持AG百家樂視訊遊戲。',
  ntc2: '2. 玩家參加本次AG活動，即視為同意本活動條款。',
  ntc3: '3. 活動期間，玩家參與“冠軍競猜活動”，需使用玩家現有用戶積分參與賽事。',
  ntc4: '4. 用戶積分賺取只限於AG平台中進行有效投注，詳細說明以及積分兌換規則請參考“用戶積分系統” 說明。',
  ntc5: '5. 為保證活動回饋廣大會員，本活動每位會員限同一帳戶及同一IP地址參加。違規者取消領獎資格，嚴重舞弊者AG有權在不事先通知情況下作封號處理或扣除相關獲利。',
  ntc6: '6. 任何會員以任何非法手段，包括但不限於對沖、合謀作弊等參加本活動，AG有權在不事先通知情況下作封號處理或扣除相關獲利。',
  ntc7: '7. 平台保有執行、修正、解釋與終止等權力。',
  ntc8: '8. 玩家盈利貢獻及總盈利不會計算所有Jackpot 派彩。',
  nFaq1: '活動入口在哪？',
  ansNFaq1: '成功進入任意百家樂房間後，在房間界面即可看見活動入口。',
  nFaq2: '如何參加最強桌主巔峰賽？',
  ansNFaq2: '進入活動入口後，點擊創建私人桌或加入私人桌並下注最少一局獲得淨贏即可參與最強桌主巔峰賽。',
  nFaq3: '我可以同時成為桌主和參與者嗎？',
  ansNFaq3: '可以，玩家可以同時擔任桌主和參與者。在你的私人桌中獲得的淨盈利會計算於玩家貢獻獎金中。若最終你的私人桌獲得獎金，你可獲得桌主及參與者的獎金。',
  nFaq4: '我可以在活動期間加入多於一張私人桌嗎？',
  ansNFaq4: '可以，在活動期間可加入多個私人桌。若玩家參與的所有私人桌均在桌主排行榜的前20名內，玩家將獲得這些私人桌的參與者獎金。',
  nFaq5: '桌主排行榜是否使用個人真錢額度投注？',
  ansNFaq5: '活動期間在私人桌所投注的金額均是使用個人真錢額度進行投注。',
  nFaq6: '如何計算總盈利及玩家頁獻盈利？',
  ansNFaq6: '玩家在百家樂私人桌中下注獲得的淨贏（扣除投注本金）將計算於玩家頁獻盈利及總盈利中。',
  nFaq7: '退出私人桌後重新加入或創建會分開計算總盈利嗎？',
  ansNFaq7: '若玩家退出私人桌並重新創建或加入私人桌後，獲得的盈利會累積計算於相同桌主名稱的私人桌。',
  nFaq8: '如何邀請玩家進入我的私人桌？',
  ansNFaq8: '成功創建公開私人桌後會有5秒提示讓桌主發送公頻邀請，點擊後會發送邀請訊息於聊天中。玩家點擊邀請訊息即可申請進入桌主的私人桌。桌主亦可透過聊天界面中的“公頻邀請“按鈕發送邀請訊息。',
  nFaq9: '如何參與冠軍競猜活動？',
  ansNFaq9: '符合資格的玩家在活動第一天完結後，點擊活動入口中的 “冠軍競猜“ 點擊 “立即投票“ 或在加入私人桌時點擊 “投票“ 即可進行投票。',
  nFaq10: '投票有任何限制嗎？',
  ansNFaq10: '在投票過程中，玩家需達到VIP 1 並支付 1,500 AG積分進行投票。每名玩家只能在活動期間投票一名桌主。確認投票後不能修改投票記錄。每位桌主只能接受500名玩家的投票。如果投票人數已達上限，玩家將無法為該桌主投票。若所有桌主均達到投票上限，玩家無法參與活動。',
  nFaq11: '怎樣領取最強桌主巔峰賽中獲得的獎金？',
  ansNFaq11: '在活動中所有獲得的獎金經審核後將即時存入玩家帳戶內，詳情可於額度記錄中查看。',
  congratsTopTable: '恭喜以下玩家成為最強桌主！',
  actualBonus: '實際獎金',
  miCardBaccaratTitle: '競咪百家樂',
  miCardBaccarat: '競咪百家樂 - 大獎連連轉',
  spinForFortune: '轉出好彩頭，大獎輕鬆贏',
  topPrizePart1: '最高獎金',
  prize: 'CNY 150,000',
  topPrizePart2: '等你來拿！',
  topPrize: '最高獎金 CNY 150,000等你來拿！',
  eventOverview: '活動簡介',
  eventOverviewDetails: '全新推出的競咪百家樂遊戲將於2024年10月28日至10月29日舉辦一場盛大的回饋活動，誠邀所有玩家踊躍參與！在活動期間，競咪百家樂桌台將舉行12場精彩絕倫的抽獎活動。在這兩天裏，無論您是進座的玩家還是旁注玩家，都可以與迷人的主播實時互動，享受前所未有的遊戲樂趣。不論輸贏，所有參與者都可贏取豐厚的獎品。不要錯過這次難得的機會，快來加入我們，一同體驗這場無與倫比的回饋盛宴吧！',
  howToParticipate: '如何參與',
  howTo1: '用戶帳號必須爲AG平台的真錢帳號',
  howTo2: '玩家需在活動前指定時間內於競咪百家樂進行有效投注達到 CNY 1,000',
  howTo3: '玩家須在活動開始前進入競咪百家樂桌台，抽獎將在隨機牌靴結束時進行。',
  howTo4: '活動開始時，需在活動界面點擊「立即參加」以確認參與',
  eventDetails: '活動詳情',
  eventRules: '活動規則',
  howToQualify: '如何獲得活動資格',
  howToQualify1: '玩家需在活動前指定時間內於競咪百家樂進行有效投注達到 CNY 1,000 ，即可自動獲得參與資格。',
  howToQualify2: '玩家須在活動開始前進入競咪百家樂桌台。 (在活動前的牌靴結束前，活動圖示上將顯示提示訊息。）',
  howToQualify3: '活動開始時，需在活動界面點擊「立即參加」以確認參與。',
  howToQualify4: '若玩家未能在活動前指定時間內達到下注門檻，或未在活動界面點擊「立即參加」，本平台將視為玩家放棄參加該場活動，並在活動開始時自動將其設為旁觀模式。',
  eventTime: '活動時間',
  eventTimeDetails: '2024年10月28日至10月29日，下午3:00至晚上11:00（北京時間）',
  eventSessions: '每天6場，兩天共12場活動。',
  howItWorks: '活動方式',
  howItWorks1: '玩家須在活動開始前進入競咪百家樂桌台，抽獎將在隨機牌靴結束時進行。',
  howItWorks2: '玩家從四個獎金中挑選一個，隨後主播轉動輪盤。如果玩家未選擇獎金，系統將自動隨機選取一個。',
  howItWorks3: '若玩家所選獎金與輪盤結果一致，所有中獎玩家分享該獎池；若不同，也可以分享 CNY 20,000 的參與獎池。若獲得獎金少於 CNY 1 ，系統將自動補發 CNY 1 給中獎玩家。',
  prizePools: '活動獎池 (CNY)',
  wheelPrizes: '輪盤獎金：150,000, 120,000, 100,000, 80,000',
  participationPrize: '參與獎金：20,000',
  eventTerms: '活動條款',
  eventTerms1: '大獎連連轉僅支持AG競咪百家樂視訊遊戲。',
  eventTerms2: '玩家參加本次AG活動，即視為同意本活動條款。',
  eventTerms3: '實際獎金將根據當時匯率計算，並與其他選擇同一獎池的得主均分。',
  eventTerms4: '為保證活動回饋廣大會員，本活動每位會員限同一帳戶及同一IP地址參加。違規者取消領獎資格，嚴重舞弊者AG有權在不事先通知情況下作封號處理或扣除相關獲利。',
  eventTerms5: '任何會員以任何非法手段，包括但不限於使用多個帳戶參加本活動，AG有權在不事先通知情況下作封號處理或扣除相關獲利。',
  eventTerms6: '平台保有執行、修正、解釋與終止等權力。',
  faqs: '常見問題',
  faq1: '如何查看大獎連連轉活動的相關資訊？',
  a1Method1: '方法一：於競咪百家樂遊戲大廳點選活動横幅查看活動資訊。',
  a1Method2: '方法二：成功進入競咪百家樂房間後，在房間界面即可點選活動圖示查看活動資訊。',
  faq2: '如何參加大獎連連轉？',
  a2: '玩家須在活動前指定時間內於競咪百家樂進行有效投注達到 CNY 1,000，並於活動開始前進入競咪百家樂桌台。在活動開始時，請在界面上點擊「立即參加」以確認您的參與。',
  faq3: '每位符合資格的玩家將獲得多少獎金？',
  a3: '獎金數額取決於輪盤轉動的結果。每場活動的最終獎金將與所有正確猜中輪盤結果的玩家共享，而未正確猜中的玩家則將共享參與獎池中的獎金。',
  faq4: '我可以參加多於一場活動並獲得資格嗎？',
  a4: '可以。每場活動前，您需要在指定時間內達到最低有效投注額 CNY 1,000，才能獲得該場活動的參與資格。每場活動結束後，您的有效投注額會重新計算。\n例子：如果您在每場活動前都達到 CNY 1,000的最低有效投注額，您就可以參與所有場次的活動。',
  faq5: '活動有設置人數上限嗎？',
  a5: '本次活動沒有設置人數上限，成功進入活動桌台的玩家並達最低投注額即可參與。',
  faq6: '如果我在活動期間斷線了，還能繼續參加活動並贏取獎金嗎？',
  a6: '玩家需在活動開始時，點擊「立即參加」以確認參加資格。如果在確認後玩家中途退出活動，仍然有資格贏取獎金。',
  faq7: '怎樣領取大獎連連轉中獲得的獎金？',
  a7: '活動獎金將在每場活動的輪盤轉動完成後發放。所有在活動中獲得的獎金經審核後會即時存入玩家賬戶內，詳情可於額度記錄中查看。'
};
