/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Button,
  ClickAwayListener,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material';

import { type IPreHeader } from '../interfaces';
import { languageCodes, languages, websiteLanguageCodes } from '../languages/languages';

import '../styles/PreHeader.scss';

interface ILinksMenuComponent { anchor: 'left' | 'right' }
export function PreHeader ({ isMobileView, setShowRankings, showRankings, t, i18n }: IPreHeader): JSX.Element {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isHoverLangIcon, setIsHoverLangIcon] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const textHighlightColor = '#FFD566';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  useEffect(() => {
    function navHighlighter (): void {
      const sections: NodeListOf<HTMLElement> = document.querySelectorAll('section[id]');
      const scrollY: number = window.scrollY;

      sections.forEach((current) => {
        if (current instanceof HTMLElement) {
          const sectionHeight: number = current.offsetHeight;
          const sectionTop: number = current.offsetTop - 220;
          const sectionId: string | null = current.getAttribute('id');

          if (sectionId === null) return;
          if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
            document.querySelector(`.navigation a[href*=${sectionId}]`)
              ?.classList.add('active-link');
          } else {
            document.querySelector(`.navigation a[href*=${sectionId}]`)
              ?.classList.remove('active-link');
          }
        }
      });

      const homeAnchor = document.getElementById('home');
      if (window.scrollY > 100) {
        homeAnchor?.classList.remove('active-link');
      } else {
        homeAnchor?.classList.add('active-link');
      }
    }

    window.addEventListener('scroll', navHighlighter);

    return () => {
      window.removeEventListener('scroll', navHighlighter);
    };
  }, []);

  const toggleDrawer = (): void => { setIsDrawerOpen(prev => !prev); };

  const changeLanguage = (languageCode: string): void => {
    void i18n.changeLanguage(languageCode);
    const params = new URLSearchParams(window.location.search);
    params.delete('lang');
    let newLangCode = languageCode;
    if (newLangCode === languageCodes.chineseSimplified) newLangCode = websiteLanguageCodes.chineseSimplified;
    if (newLangCode === languageCodes.chineseTraditional) newLangCode = websiteLanguageCodes.chineseTraditional;

    params.append('lang', newLangCode);

    const baseURL = window.location.origin;
    const pathName = window.location.pathname;
    const newURL = baseURL + pathName + `?${params.toString()}`;
    const websiteTitle = t('miCardBaccaratTitle');
    document.title = t('miCardBaccaratTitle');
    window.history.pushState({ Title: websiteTitle, Url: newURL }, websiteTitle, newURL);
  };
  const defaultTextColor = '#c7c6c6';
  const textColor: string = isHoverLangIcon ? textHighlightColor : defaultTextColor;

  const linksArray: string[] = ['eventOverview', 'howToParticipate', 'eventDetails', 'faq'];

  const id = open ? 'simple-popper' : undefined;

  const handleHome = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setShowRankings(false);
  };

  const languageComponentMenu = (): JSX.Element => (
    <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 5 }}>
      <Paper sx={{ background: 'black', color: defaultTextColor }}>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList id="basic-menu">
            {languages.map(language => (
              <MenuItem key={language.name}
                sx={{ '&:hover': { color: textHighlightColor } }}
                onClick={() => {
                  handleClose();
                  changeLanguage(language.languageCode);
                }}>
                {language.name}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );

  const homeAnchorTag = (): JSX.Element => (
    <a
      href="#"
      onClick={handleHome}
      id="home"
      className="active-link">
      {t('miCardBaccaratTitle')}
    </a>
  );

  const handleLeaderboardClick = (link: string): void => {
    setShowRankings(link === 'leaderboard');
    if (link === 'leaderboard') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const linksMenuComponent = ({ anchor }: ILinksMenuComponent): JSX.Element => (
    <>
      <Button className="menu-icon-container" onClick={toggleDrawer}>
        <MenuIcon sx={{ color: defaultTextColor, height: '40px', width: '40px' }} />
      </Button>
      <Drawer anchor={anchor} open={isDrawerOpen} onClose={toggleDrawer}>
        <Box sx={{
          height: '100vh',
          width: '200px',
          overflow: 'hidden',
          color: defaultTextColor,
          background: 'black',
          paddingTop: '16px'
        }}
        role="presentation"
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
        >
          <List>
            <ListItemButton>
              {homeAnchorTag()}
            </ListItemButton>
            {linksArray.map(link => (
              <ListItem key={link} sx={{
                margin: '16px 0px',
                '&:hover a': {
                  color: textHighlightColor
                }
              }} disablePadding>
                <ListItemButton>
                  <a href={`#${link}`} onClick={() => {
                    handleLeaderboardClick(link);
                  } }>{t(link)}</a>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );

  const checkActiveLinkLeaderboard = (link: string): string => {
    if (link === 'leaderboard') {
      if (showRankings) return 'active-link';
    }

    return '';
  };

  return (
    <section
      className='preheader'
      id='preheader'
      style={{ justifyContent: !isMobileView ? 'flex-end' : 'space-between' }}>
      {isMobileView && (linksMenuComponent({ anchor: 'left' }))}
      {!isMobileView && (
        <div className="right-container">
          <nav className="navigation" aria-label="Primary">
            <ul>
              <li>
                {homeAnchorTag()}
              </li>
              {linksArray.map(link => (
                <li key={link}>
                  <a href={`#${link}`}
                    className={checkActiveLinkLeaderboard(link)}
                    onClick={() => {
                      handleLeaderboardClick(link);
                    }}>
                    {t(link)}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <div className="drop-down" data-testid="languages-dropdown-btn">
            <IconButton
              id="basic-button"
              aria-controls={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              aria-describedby={id}
              onMouseOver={() => { setIsHoverLangIcon(true); }}
              onMouseOut={() => { setIsHoverLangIcon(false); }}
              onClick={handleClick}
            >
              <LanguageIcon sx={{ color: textColor }}/>
              <p className={ isHoverLangIcon ? 'colored-text' : '' }>
                {languages.find(el => el.languageCode === i18n.resolvedLanguage)?.name}
              </p>
            </IconButton>
            {languageComponentMenu()}
          </div>
        </div>
      )}
      {isMobileView && (
        <div className="drop-down">
          {languageComponentMenu()}
          <IconButton
            id="basic-button"
            aria-controls={open ? 'simple-popper' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            aria-describedby={id}
            onMouseOver={() => { setIsHoverLangIcon(true); }}
            onMouseOut={() => { setIsHoverLangIcon(false); }}
            onClick={handleClick}
          >
            <LanguageIcon sx={{ color: textColor }}/>
            <p style={{ color: textColor }}>
              {languages.find(el => el.languageCode === i18n.resolvedLanguage)?.name}
            </p>
          </IconButton>
        </div>
      )}
    </section>
  );
};
