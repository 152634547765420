import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import qIcon from '../assets/newTournament/q_icon.webp';

import { type ITournamentRules } from '../interfaces';

export const NewTournamentFAQ = ({ t }: ITournamentRules): JSX.Element => {
  const animationDuration = 300;
  const iconStyle = { color: 'white', height: '48px', width: '48px' };

  const initialQuestionsObj = {
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false,
    q6: false,
    q7: false
  };

  const [questionsObj, setQuestionsObj] =
    useState<Record<string, boolean>>(initialQuestionsObj);

  const handleQuestionsObj = (question: string): void => {
    if (!questionsObj[question]) {
      setQuestionsObj({ ...initialQuestionsObj, [question]: true });

      const questionNumber: string = question.split('')[1];
      const element: HTMLElement | null =
        document.getElementById(`nt${questionNumber}`);

      if (element != null) {
        setTimeout(() => {
          element.scrollIntoView(true);
        }, animationDuration);
      }
    }

    if (questionsObj[question]) {
      setQuestionsObj({ ...initialQuestionsObj });
    }
  };

  const questionAnswerTemplate = (question: string,
    label: string, answer: string, id: string):
  JSX.Element => (
    <div className={`${questionsObj[question] ? 'back-shadow' : ''}`}>
      <button id={id} onClick={() => { handleQuestionsObj(question); } }>
        <div className={`label-container ${questionsObj[question] ? 'no-border' : ''}`}>
          <aside>
            <img src={qIcon} width="32px" height="32px" alt="q icon" />
            <strong>{t(label)}</strong>
          </aside>
          {questionsObj[question]
            ? <KeyboardArrowDownIcon sx={iconStyle} />
            : <KeyboardArrowUpIcon sx={iconStyle} />}
        </div>
      </button>
      <AnimateHeight duration={animationDuration} height={ questionsObj[question] ? 'auto' : 0}>
        <section className="tr-section-container">
          <ol>
            {question === 'q1'
              ? <>
                <li>{t('a1Method1')}</li>
                <li>{t('a1Method2')}</li>
              </>
              : <li>{t(answer)}</li>}
          </ol>
        </section>
      </AnimateHeight>
    </div>
  );

  return (
    <div className="tournament-setting-container">
      {Array.from({ length: 7 }, (_, i) => i + 1).map(num => (
        questionAnswerTemplate(
          `q${num}`,
          `faq${num}`,
          `a${num}`,
          `nt${num}`)
      ))}
    </div>
  );
};
